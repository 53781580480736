import { Avatar, Box, Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import ApplicationIcon from '../images/Applications_Icon.svg';
import ExternalLinkIcon from '../images/ShareLinkIcon.svg';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { slugify } from './VacancyListEntry';
import EditIcon from '@mui/icons-material/Edit';
import { useContext } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { InSiteContext } from '../context/InSiteContext';

const OverviewHeaderTitle = ({ vacancyData, vacancyIsLoading, subPath = 'overview' }) => {
  const { t } = useTranslation('common');
  const insiteUrl = useContext(InSiteContext);
  const params = useParams();
  const location = useLocation();
  const vacancyId = params.VacancyID;
  const title = location?.state?.data?.title || '';
  return (
    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={4}>
      <Avatar
        alt="Vacancies to evaluatie"
        src={ApplicationIcon}
        sx={(theme) => ({
          bgcolor: theme.vars.palette.primary.main,
          p: '1em',
          width: 88,
          height: 88,
          display: {
            xs: 'none',
            sm: 'flex',
          },
          boxShadow: '0px 15px 15px rgba(0,0,0,0.06)',
          '& .MuiAvatar-img': {
            objectFit: 'contain',
            width: 40,
            height: 33,
          },
        })}
      />
      <Divider
        orientation="vertical"
        flexItem
        sx={(theme) => ({
          display: {
            xs: 'none',
            sm: 'block',
          },
        })}
      />
      <Box>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography
            variant="h2"
            component="h1"
            sx={{
              flex: 1,
              maxWidth: '50ch',
            }}
          >
            {vacancyIsLoading && title}
            {vacancyData && !vacancyIsLoading && <>{vacancyData.VacancyTitle}</>}
          </Typography>
          <>
            {vacancyData.VacancyTitle && (
              <Tooltip arrow title={t('vacancies.list.viewhere')}>
                <IconButton
                  className="ev_view-vacancy-details"
                  id={`view-details-${vacancyId}`}
                  sx={{
                    padding: 0,
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                  href={`https://werkenbijdemo.triplehosting.nl/vacatures/${slugify(
                    vacancyData.VacancyTitle
                  )}`}
                  target="_blank"
                >
                  <Avatar
                    src={ExternalLinkIcon}
                    sx={{
                      width: 24,
                      height: 24,
                      padding: '2px',
                      borderRadius: 1,
                      '& .MuiAvatar-img': {
                        objectFit: 'contain',
                      },
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
            {vacancyData.VacancyID && (
              <Tooltip arrow title={t('vacancies.list.edit')}>
                <IconButton
                  className="ev_view-vacancy-details"
                  id={`edit-details-${vacancyData.VacancyID}`}
                  sx={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                  href={`${insiteUrl}/vacature-prs?VcSn=${vacancyData.VacancyID}`}
                  target="_blank"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        </Stack>
        <Typography variant="body" component="p">
          {
            <>
              {t('vacancies.header.role')}:&nbsp;
              {vacancyData.Role === 'chair_person'
                ? t('vacancies.header.chairperson')
                : vacancyData.Role === 'reviewer'
                  ? t('vacancies.header.responsible_person')
                  : undefined}

              {vacancyData?.AssessObjectively && (
                <>
                  <Tooltip title={t('vacancies.assessobjectively')}>
                    <IconButton>
                      <InfoOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </>
          }
        </Typography>
      </Box>
    </Stack>
  );
};

export default OverviewHeaderTitle;
