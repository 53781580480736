import { TableRow, TableCell, styled, Typography, Button, Stack, Box, Avatar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import OnHoldIcon from '../images/OnHoldIcon.svg';
import RejectedIcon from '../images/RejectedIcon.svg';
import CheckIcon from '../images/Check_Icon.svg';
import { useContext } from 'react';
import { InSiteContext } from '../context/InSiteContext';

function CandidateListEntry({ candidate }) {
  const { t } = useTranslation('common');
  const insiteUrl = useContext(InSiteContext);

  const StyledCell = styled(TableCell)({
    border: 'none',
    paddingInline: '2em !important',
    paddingBlock: '2em !important',
    alignItems: 'start',
    textAlign: 'left',
  });

  const RatingIcon = ({ color, count, icon }) => {
    return (
      <Box
        sx={{
          textAlign: 'center',
          maxWidth: '120px',
          display: 'block',
          width: 48,
          height: 48,
          marginLeft: 'auto',
          marginRight: 'auto',
          borderRadius: '100%',
          backgroundColor: '#F5F5F5',
        }}
      >
        <Avatar
          alt="icon"
          src={icon}
          sx={{
            width: 26,
            height: 26,
            marginLeft: 'auto',
            marginRight: 'auto',
            bgcolor: 'transparent',
            borderRadius: '0',
          }}
        ></Avatar>
        <Typography
          variant="h4"
          color={color}
          sx={{
            fontSize: '1rem !important',
            fontWeight: 500,
            transition: 'all ease-in-out 250ms',
            color: `${color} !important`,
          }}
        >
          {count}
        </Typography>
      </Box>
    );
  };

  const ratingCounts = candidate.Rating[0]?.Rating !== '?' ? candidate.Rating.reduce(
    (acc, { Rating }) => {
      acc[Rating] = (acc[Rating] || 0) + 1;
      return acc;
    },
    { 1: 0, 2: 0, 3: 0 }
  ) : { 1: '?', 2: '?', 3: '?' };

  const getDateString = (date) => {
    date = new Date(date);
    const weekday = date.toLocaleString('default', { weekday: 'long' });
    const capWeekday = weekday.charAt(0).toUpperCase() + weekday.slice(1);

    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const capMonth = month.charAt(0).toUpperCase() + month.slice(1);
    const year = date.getFullYear();

    return `${capWeekday} ${day} ${capMonth} ${year}`;
  };

  return (
    <TableRow className="table-row-legend" width="100%" sx={{ background: 'white' }}>
      <StyledCell>
        <Typography variant="body1" sx={{}}>
          {candidate.ApplicantName}
        </Typography>
      </StyledCell>
      <StyledCell>
        <Stack direction="row" spacing={2}>
          <RatingIcon color="#17B733" count={ratingCounts['3']} icon={CheckIcon}></RatingIcon>
          <RatingIcon color="#0060AA" count={ratingCounts['2']} icon={OnHoldIcon}></RatingIcon>
          <RatingIcon color="#E81515" count={ratingCounts['1']} icon={RejectedIcon}></RatingIcon>
        </Stack>
      </StyledCell>
      <StyledCell>
        <Typography variant="body1" sx={{}}>
          {candidate.VacancyTitle}
        </Typography>
      </StyledCell>
      <StyledCell>
        <Typography variant="body1" sx={{}}>
          {t(`candidates.status.${candidate.StatusCode}`)}
        </Typography>
      </StyledCell>
      <StyledCell>
        <Typography variant="body1" sx={{}}>
          {getDateString(candidate.Date)}
        </Typography>
      </StyledCell>
      <StyledCell width="10%">
        <Button
          aria-label={t('candidates.list.goto')}
          size="small"
          color="primary"
          variant="contained"
          sx={{
            '&.MuiButtonBase-root': {
              minWidth: 'unset',
              width: '50%',
              height: 'auto',
            },
          }}
          href={`${insiteUrl}/sollicitant-stamkaart-prs?EmId=${candidate.EmId}&BcId=${candidate.BcId}`}
          target="_blank"
        >
          <ArrowForwardIosIcon fontSize="small" />
        </Button>
      </StyledCell>
    </TableRow>
  );
}

export default CandidateListEntry;
