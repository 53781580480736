import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import SearchIcon from '@mui/icons-material/Search';
import {
  Badge,
  Fab,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  Modal,
  Pagination,
  Select,
  Stack,
  Table,
  TableBody,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import FuzzySearch from 'fuzzy-search';
import _orderBy from 'lodash/orderBy';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { XIcon } from '../components/Icons';
import RecruitmentHeader from '../components/RecruitmentHeader';
import VacancyListEntry from '../components/VacancyListEntry';
import useAfasData from '../hooks/useAfasData';

function VacanciesList() {
  const [mobileDevice, setMobileDevice] = useState(false);
  const [mobileWarning, setMobileWarning] = useState(false);
  useEffect(() => {
    if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setMobileDevice(true);
      setMobileWarning(true);
    }
  }, [mobileDevice]);

  const handleClose = () => {
    setMobileWarning(false);
  };
  const modalBoxStyle = {
    position: 'absolute',
    top: '100px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '94%',
    maxWidth: '1100px',
    bgcolor: 'rgba(30, 35, 39, .92)',
    minHeight: '500px',
    display: 'flex',
    alignItems: 'center',
    p: 4,
  };

  const {
    data: listData = [],
    isLoading: listLoading,
    errorMessage: listErrorMessage,
  } = useAfasData('/vacancies/list', {});

  const {
    data: enrichedData = [],
    isLoading: enrichedLoading,
  } = useAfasData('/vacancies/enriched', {});

  const { data: countData = [] } = useAfasData('/vacancies/reviewed', {});

  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

  const [enrichedResult, setEnrichedResult] = useState([]);

  useEffect(() => {
    const debounceId = setTimeout(() => {
      setDebouncedSearchTerm(searchValue);
    }, 1000);
    return () => {
      clearTimeout(debounceId);
    };
  }, [searchValue]);

  useEffect(() => {
    if (debouncedSearchTerm !== '') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'searchVacancyOverview',
        search: debouncedSearchTerm,
      });
    }
  }, [debouncedSearchTerm]);

  const vacancySearcher = new FuzzySearch(enrichedResult, ['VacancyTitle', 'VacancyID'], {
    caseSensitive: false,
    sort: true,
  });

  const { t } = useTranslation('common');

  const sortMap = {
    'vacancies.list.sort.alphaasc': { key: 'VacancyTitle', order: 'asc', alnum: true },
    'vacancies.list.sort.alphadesc': { key: 'VacancyTitle', order: 'desc', alnum: true },
    'vacancies.list.sort.idasc': { key: 'VacancyID', order: 'asc', alnum: false },
    'vacancies.list.sort.iddesc': { key: 'VacancyID', order: 'desc', alnum: false },
    'vacancies.list.sort.nasc': { key: 'AmountCandidates', order: 'asc', alnum: false },
    'vacancies.list.sort.ndesc': { key: 'AmountCandidates', order: 'desc', alnum: false },
  };

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [paginatedResult, setPaginatedResult] = useState([]);
  const [newResult, setNewResult] = useState([]);
  const [sort, setSort] = useState('vacancies.list.sort.iddesc');
  const [sortedResult, setSortedResult] = useState([]);

  const [finishedLoading, setFinishedLoading] = useState(false);

  useEffect(() => {
    if (!listLoading && !enrichedLoading) setFinishedLoading(true);
  }, [listLoading, enrichedLoading])

  useEffect(() => {
    const enrichedList = listData.map(vacancy => ({
      ...vacancy,
      Enriched: enrichedData.some(item => item["Vacancy_ID"] === vacancy.VacancyID)
    }));

    setEnrichedResult(enrichedList);
    setNewResult(enrichedList);
  }, [enrichedData, finishedLoading, listData]);

  useEffect(() => {
    setNewResult(vacancySearcher.search(searchValue));
    setPage(1);
  }, [searchValue]);

  useEffect(() => {
    if (newResult.length > 0 && rowsPerPage > 0) {
      setPageCount(Math.ceil(newResult.length / rowsPerPage));
    }
  }, [newResult, rowsPerPage]);

  useEffect(() => {
    if (searchValue === '') {
      const sortOptions = sortMap[sort];
      setSortedResult(
        _orderBy(
          newResult,
          sortOptions.alnum ? [(vacancy) => vacancy[sortOptions.key].toLowerCase()] : sortOptions.key,
          sortOptions.order
        )
      );
    } else {
      setSortedResult(newResult);
    }
    setRowsPerPage(newResult.length > 10 ? 10 : newResult.length);
  }, [sort, newResult, searchValue]);

  useEffect(() => {
    if (rowsPerPage > 0) {
      setPageCount(Math.ceil(sortedResult.length / rowsPerPage));
    }
    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    setPaginatedResult(sortedResult.slice(startIndex, endIndex));
  }, [sortedResult, page, rowsPerPage]);

  useEffect(() => {
    setPage(1);
  }, [sort]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const handleChangeSort = (event) => {
    setSort(event.target.value);
  };

  return (
    <>
      <RecruitmentHeader numberVacancies={listData.length} />

      <Box>
        {mobileDevice && (
          <>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={mobileWarning}
              disableAutoFocus={true}
              onClose={handleClose}
              closeAfterTransition
              slots={{ backdrop: Box }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Box sx={modalBoxStyle}>
                <Box
                  className="fixed-close-button"
                  sx={{
                    position: 'absolute',
                    top: '1em',
                    right: '2em',
                  }}
                >
                  <Fab size="small" color="light" onClick={handleClose}>
                    <XIcon width="14" height="14" />
                  </Fab>
                </Box>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                  sx={(theme) => ({
                    fontSize: '28px',
                    lineHeight: '2em',
                    textAlign: 'center',
                    maxWidth: '65ch',
                    margin: '1em auto',
                  })}
                >
                  {t('vacancies.list.mobilewarning')}
                </Typography>
              </Box>
            </Modal>
          </>
        )}
        <Box sx={{ mb: 6 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={10}>
              <Badge
                badgeContent={listData.length}
                color="primary"
                overlap="rectangular"
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={(theme) => ({
                  '& .MuiBadge-badge': {
                    bgcolor: theme.vars.palette.primary.main,
                    color: theme.vars.palette.background.default,
                    width: 38,
                    height: 38,
                    borderRadius: 38 / 2,
                    fontWeight: 700,
                    right: -20,
                    top: -12,
                    fontSize: '18px',
                  },
                })}
              >
                <Box>
                  <Typography
                    variant="h2"
                    sx={{
                      fontSize: '20px !important',
                      fontWeight: 700,
                      letterSpacing: '0.7px',
                    }}
                  >
                    {t('vacancies.list.title')}
                  </Typography>
                </Box>
              </Badge>
              <Box className="rounded-box"
                sx={(theme) => ({
                  display: 'flex',
                  flexWrap: 'wrap',
                  bgcolor: theme.vars.palette.background.default,
                  boxShadow: '0px 8px 8px rgba(0,0,0,0.15)',
                })}
              >
                <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                  <SearchIcon sx={{ stroke: 'black' }} />
                </IconButton>
                <InputBase
                  sx={{
                    flex: 1,
                    pr: 2,
                    pl: 1,
                    fontSize: '16px',
                    letterSpacing: 'normal',
                  }}
                  placeholder={t('vacancies.list.search')}
                  inputProps={{ 'aria-label': t('vacancies.list.search') }}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </Box>
            </Stack>
            <Stack direction="row" spacing={3} alignItems="center" justifyContent="center">
              <FormControl
                variant="standard"
                sx={{
                  width: 'auto',
                  '&.MuiFormControl-root .MuiInputBase-root': {
                    mt: '0',
                  },
                }}
                size="small"
              >
                <InputLabel id="rows-sort-label">{ }</InputLabel>
                <Select
                  labelId="rows-sort-label"
                  id="rows-sort"
                  value={sort}
                  label={'Sort'}
                  renderValue={() => t('vacancies.list.sort.title')}
                  onChange={handleChangeSort}
                  className="filter-select"
                  IconComponent={KeyboardArrowDownRoundedIcon}
                >
                  {Object.keys(sortMap).map((val, i) => {
                    return (
                      <MenuItem key={i} value={val}>
                        {t(`${val}`)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Typography variant="body2" component="p" fontSize="16px" letterSpacing="0.56px">
                {page} - {paginatedResult.length} {t('vacancies.header.of')} {sortedResult.length}{' '}
                {t('vacancies.header.vacancies')}
              </Typography>
            </Stack>
          </Stack>
        </Box>
        <Box>
          {!finishedLoading && <LinearProgress mt={2} />}
          {finishedLoading && paginatedResult.length > 0 && (
            <>
              <Box mt={2} mb={2}>
                {paginatedResult.map((vacancy) => {
                  let reviewCount = countData.find((count) => count.VacancyID === vacancy.VacancyID);
                  return (
                    <Box
                      px={3}
                      mb={2}
                      pt="1em"
                      pb="1.25em"
                      key={vacancy.VacancyID}
                      height={171}
                      width="100%"
                      sx={(theme) => ({
                        backgroundColor: theme.vars.palette.background.default,
                        boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.05)',
                        borderRadius: '5px',
                      })}
                    >
                      <Table height="100%">
                        <TableBody>
                          <VacancyListEntry count={reviewCount?.Count ?? '-'} vacancy={vacancy} />
                        </TableBody>
                      </Table>
                    </Box>
                  );
                })}
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={3}
                  justifyContent="flex-end"
                  sx={{ px: '2em', mt: 2, mb: 3 }}
                >
                  <Pagination count={pageCount} page={page} onChange={handleChangePage} />
                  <FormControl
                    size="small"
                    variant="standard"
                    sx={{
                      width: 'auto',
                      '&.MuiFormControl-root .MuiInputBase-root': {
                        mt: 0,
                      },
                    }}
                  >
                    <InputLabel id="rows-select-label"></InputLabel>
                    <Select
                      MenuProps={{ autoFocus: false, disableAutoFocus: true }}
                      labelId="rows-select-label"
                      id="rows-select"
                      value={rowsPerPage}
                      label={'Rows'}
                      className="filter-select"
                      IconComponent={KeyboardArrowDownRoundedIcon}
                      displayEmpty
                      onChange={handleChangeRowsPerPage}
                      renderValue={() => t('vacancies.list.pagerows')}
                    >
                      {[newResult.length, 5, 10, 25, 50, 100].map((amountOfRows, i) => {
                        return i === 0 && amountOfRows <= 100 ? (
                          <MenuItem key={i} value={amountOfRows}>
                            <ListItemText
                              primary={amountOfRows}
                              secondary={`(${t('vacancies.footer.all')})`}
                            />
                          </MenuItem>
                        ) : (
                          amountOfRows < newResult.length && (
                            <MenuItem key={i} value={amountOfRows}>
                              <ListItemText primary={amountOfRows} />
                            </MenuItem>
                          )
                        );
                      })}
                    </Select>
                  </FormControl>
                </Stack>
              </Box>
            </>
          )}
          {finishedLoading && searchValue && paginatedResult.length === 0 && (
            <Typography align="center" variant="h6">
              {t('vacancies.list.noresults')}
            </Typography>
          )}
          {listErrorMessage && (
            <Typography align="center" variant="h6">
              {listErrorMessage}
            </Typography>
          )}
        </Box>
        <Outlet />
      </Box>
    </>
  );
}

export default VacanciesList;
