import { Avatar, Badge, Box, Divider, Fab, Stack, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CheckIcon, CommitteeReviewIcon, HorizontalSwapIcon, OnHoldIcon, StarIcon, XIcon } from '../Icons';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import styled from '@emotion/styled';
import { getStatusColor } from '../../containers/VacancyOverview';

const DetailDialogSummaryCircles = ({ color, label, number, icon }) => {
  return (
    <Box>
      <Box
        className="cards-header-button"
        sx={{
          textAlign: 'center',
          maxWidth: '120px',
          p: 2,
          display: 'block',
          width: 105,
          height: 105,
          bgcolor: '#FFF',
          marginLeft: 'auto',
          marginRight: 'auto',
          borderRadius: '100%',
          boxShadow: '0px 8px 8px rgba(0,0,0,0.1)'
        }}
      >
        <Avatar
          sx={{
            width: 40,
            height: 40,
            marginLeft: 'auto',
            marginRight: 'auto',
            bgcolor: 'transparent',
            borderRadius: '0',
          }}
        >
          {icon}
        </Avatar>
        <Box mt={1}>
          <Typography
            variant="h4"
            color={color}
            sx={{
              fontSize: '1rem !important',
              fontWeight: 500,
              transition: 'all ease-in-out 250ms',
              color: `${color} !important`
            }}
          >
            {number}
          </Typography>
        </Box>
      </Box>
      <Typography
        mt={2}
        sx={{
          minWidth: '90px',
          whiteSpace: 'pre-wrap',
          textAlign: 'center',
          maxWidth: '120px',
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};

const DetailDialogGiveReviewCircle = ({ color, label, icon }) => {
  return (
    <Box>
      <Fab
        className="cards-header-button"
        onClick={() => {
          document.getElementById('detail-dialog').style.minHeight = '6000px';
          setTimeout(() => {
            document.getElementById('review-applicant')?.scrollIntoView({ behavior: 'instant' });
          }, 300);
          setTimeout(() => {
            document.getElementById('detail-dialog').style.minHeight = 'unset';
          }, 600);
        }}
        sx={{
          textAlign: 'center',
          maxWidth: '120px',
          p: 2,
          width: 105,
          height: 105,
          bgcolor: color,
          marginLeft: 'auto',
          marginRight: 'auto',
          borderRadius: '100%',
          border: '1px solid ' + color,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: color,
            boxShadow: 'none',
          },
        }}
      >
        <Avatar
          sx={{
            width: 40,
            height: 40,
            marginLeft: 'auto',
            marginRight: 'auto',
            bgcolor: color,
            borderRadius: '0',
          }}
        >
          {icon}
        </Avatar>
      </Fab>
      <Typography
        mt={2}
        sx={{
          whiteSpace: 'pre-wrap',
          textAlign: 'center',
          maxWidth: '120px',
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};

const StatusAvatar = styled(Avatar)(({ theme }) => theme.unstable_sx({
  borderRadius: '50%',
  width: 105,
  height: 105,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
  boxShadow: '0px 8px 8px rgba(0,0,0,0.1)',
}));

function getAmount(members, ratingMustBe) {
  if (members) {
    return Object.values(members).filter((value) => value?.Rating === ratingMustBe)?.length;
  }
  return 0;
}

const TooltipInner = forwardRef(function TooltipInner(props, ref) {
  return (
    <span {...props} ref={ref}>
      {props?.children}
    </span>
  );
});

function BadgeEmailSent({ children, application }) {
  const { t } = useTranslation('common');
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={
        application.StatusCode === '009' ||
          application.StatusCode === '007' ||
          application.StatusCode === '006' ? (
          <Tooltip arrow title={t('cards.card.contacted')}>
            <TooltipInner>
              <Box
                sx={{
                  backgroundColor: getStatusColor(application.StatusCode),
                  borderRadius: '50%',
                  width: '30px',
                  height: '30px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '1.125rem',
                  transform: 'translate(0%, -10%)',
                  outlineOffset: '0px',
                  outlineWidth: '3px',
                  outlineStyle: 'solid',
                }}
              >
                <MarkEmailReadOutlinedIcon fontSize=".9rem" htmlColor="#FFF" />
              </Box>
            </TooltipInner>
          </Tooltip>
        ) : (
          ''
        )
      }
    >
      {children}
    </Badge>
  );
}

const DetailDialogSummary = ({ applicationData, members, role }) => {
  const { t } = useTranslation('common');

  const [canViewComments, setCanViewComments] = useState(false);
  useEffect(() => {
    if (!applicationData?.AssessObjectively) {
      return setCanViewComments(true);
    }
    if (members && applicationData?.AssessObjectively && Object.values(members).find(member => member.IsSelf)) {
      return setCanViewComments(true);
    }
    return setCanViewComments(false);
  }, [members, applicationData]);

  const amountInvited = useMemo(() => canViewComments ? getAmount(members, 3) : '?', [members, canViewComments]);
  const amountOnHold = useMemo(() => canViewComments ? getAmount(members, 2) : '?', [members, canViewComments]);
  const amountRejected = useMemo(() => canViewComments ? getAmount(members, 1) : '?', [members, canViewComments]);
  const amountOfCommitteeMembers = applicationData?.CommitteeMembersCount || 0;
  const amountOfCommitteeMembersVoted = amountInvited + amountOnHold + amountRejected;

  return (
    <>
      <Box mb={3}>
        <Typography variant="h4">{t('cards.card.colleaguesreview')}</Typography>
      </Box>
      <Box>
        <Stack direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
          <Stack direction="row" spacing={6} alignItems="flex-start" mb={4} mt={4}>
            <DetailDialogSummaryCircles
              color="#17B733"
              label={t('cards.card.inviteadvice')}
              number={amountInvited}
              icon={<CheckIcon width="40" height="40" />}
            />
            <DetailDialogSummaryCircles
              color="#0060AA"
              label={t('cards.card.onholdadvice')}
              number={amountOnHold}
              icon={<OnHoldIcon width="40" height="40" />}
            />
            <DetailDialogSummaryCircles
              color="#E81515"
              label={t('cards.card.rejectadvice')}
              number={amountRejected}
              icon={<XIcon width="40" height="40" />}
            />
            {['reviewer', 'chair_person'].includes(role) && applicationData.StatusCode === '002' && (
              <>
                <Divider orientation="vertical" flexItem />
                <DetailDialogGiveReviewCircle
                  color="#EC7A08"
                  label={t('cards.card.review')}
                  icon={
                    <StarIcon
                      sx={{ width: 40, height: 40 }}
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      stroke="#FFF"
                    />
                  }
                />
                <Divider orientation="vertical" flexItem />
              </>
            )}
            {['chair_person'].includes(role) && ['004', '009'].includes(applicationData.StatusCode) && (
              <>
                <Divider orientation="vertical" flexItem />
                <DetailDialogGiveReviewCircle
                  color="#afafaf"
                  label={t('cards.card.changestatus')}
                  icon={<HorizontalSwapIcon width={35} height={40} fill="#FFF" />}
                />
                <Divider orientation="vertical" flexItem />
              </>
            )}
          </Stack>
          <Box mb={2} mt={2}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="flex-start"
              alignItems="center"
              sx={(theme) => ({
                maxWidth: '560px',
                padding: '2.5em 3.5em',
                bgcolor: theme.vars.palette.grey.A100,
              })}
            >
              <Box>
                <Typography
                  sx={(theme) => ({
                    fontSize: '1rem',
                    lineHeight: '2rem',
                    fontWeight: 700,
                    color: theme.vars.palette.primary.main,
                  })}
                >
                  {/* Selection Committee  */}
                  {applicationData?.StatusCode === '002' && (
                    <>
                      {amountOfCommitteeMembersVoted}/{amountOfCommitteeMembers}
                      &nbsp;{t('cards.card.committeemembersreview')}
                    </>
                  )}
                  {/* Other than open committee */}
                  {applicationData?.StatusCode !== '002' && <>{t('cards.card.chairpersonchose')}</>}
                </Typography>
              </Box>
              <Box>
                {/* Selection Committee  */}
                {applicationData?.StatusCode === '002' && (
                  <BadgeEmailSent application={applicationData}>
                    <StatusAvatar
                      sx={(theme) => ({
                        backgroundColor: 'transparent',
                        color: theme.vars.palette.primary.main,
                      })}
                    >
                      <CommitteeReviewIcon width="57" height="57" fill="currentColor" />
                    </StatusAvatar>
                  </BadgeEmailSent>
                )}
                {/* Invited */}
                {applicationData?.StatusCode === '003' && (
                  <Box sx={{ textAlign: 'center' }}>
                    <BadgeEmailSent application={applicationData}>
                      <StatusAvatar className='invited-avatar'
                        sx={{
                          color: '#17B733',
                        }}>
                        <CheckIcon stroke="#fff" width="32" height="32" />
                      </StatusAvatar>
                    </BadgeEmailSent>
                    <Typography
                      mt={2}
                      variant="h6"
                      sx={{
                        fontSize: '.85rem',
                        minWidth: '120px',
                        whiteSpace: 'pre-wrap',
                      }}
                      color="initial"
                    >
                      {t('cards.card.tobeinvited')}
                    </Typography>
                  </Box>
                )}
                {/* On Hold */}
                {(applicationData?.StatusCode === '004' || applicationData?.StatusCode === '009') && (
                  <Box sx={{ textAlign: 'center' }}>
                    <BadgeEmailSent application={applicationData}>
                      <StatusAvatar className='on-hold-avatar'>
                        <OnHoldIcon
                          fill="#FFF"
                          stroke="#FFF"
                          width="33"
                          height="33"
                        />
                      </StatusAvatar>
                    </BadgeEmailSent>
                    <Typography
                      mt={2}
                      variant="h6"
                      sx={{
                        fontSize: '.85rem',
                        minWidth: '120px',
                        whiteSpace: 'pre-wrap',
                      }}
                      color="initial"
                    >
                      {t('cards.card.onhold')}
                    </Typography>
                  </Box>
                )}
                {/* Rejected */}
                {(applicationData?.StatusCode === '005' || applicationData?.StatusCode === '008') && (
                  <Box sx={{ textAlign: 'center' }}>
                    <BadgeEmailSent application={applicationData}>
                      <StatusAvatar className="rejected-avatar">
                        <XIcon fill="#FFF" stroke="#FFF" width="33" height="33" />
                      </StatusAvatar>
                    </BadgeEmailSent>
                    <Typography
                      mt={2}
                      variant="h6"
                      sx={{
                        fontSize: '.85rem',
                        minWidth: '120px',
                        whiteSpace: 'pre-wrap',
                      }}
                      color="initial"
                    >
                      {t('cards.card.rejected')}
                    </Typography>
                  </Box>
                )}
                {/* Invited and date chosen */}
                {(applicationData.StatusCode === '007' || applicationData.StatusCode === '006') && (
                  <Box sx={{ textAlign: 'center' }}>
                    <BadgeEmailSent application={applicationData}>
                      <StatusAvatar
                        sx={(theme) => ({
                          flexDirection: 'column',
                          fontWeight: '700',
                        })}
                      >
                        {t('cards.card.invited')}
                      </StatusAvatar>
                    </BadgeEmailSent>
                  </Box>
                )}
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default DetailDialogSummary;
